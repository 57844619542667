@import '../../lib/common/theme';

.file-label {
  display: inline-block;
  font-size: $medium;
  color: $secondaryTextHighlight;
  text-align: center;
  background-color: $primaryBackground;
  border: 1px solid $primaryTextHighlight2;
  border-radius: 2px;
  padding: $small;
  text-decoration: none;
  font-weight: 400;
  min-width: 200px;
  line-height: 24px;
  cursor: pointer;
  margin-right: $medium;

  &:active {
    background-color: $secondaryBackground;
  }

  &:hover {
    border-color: 1px solid $primaryTextHighlight1;
    font-weight: 800;
  }
}