.input-box {
	flex-grow: 1;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #9cdcfe;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	margin: 8px 0 0 0;
}
.input-box:focus {
	color: #9cdcfe;
	background-color: #fff;
	border-color: #9cdcfe;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}
.input-box:focus + .search-result {
	display: block;
}
.search-result:hover {
	display: block;
}
.search-result {
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	margin-top: 0;
	list-style: none;
	padding-inline-start: 0;
	display: none;
	max-height: 9rem;
	overflow: scroll;
}

.search-result > .list-result {
	padding: 10px;
}
/* checkbox css starts here */
/* The container */
.container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 17px;
	color: #9cdcfe;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #eee;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.container:hover {
	color: #dcdcaa;
	background-color: #1e1e1e;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
	background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 6px;
	top: 2px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.multiSelect {
	text-align: left;
	margin: 12px;
	display: flex;
    flex-direction: column;
}
/* chip css */
.chip {
	flex-grow: 1;
}
.chip-body {
	height: 20px;
	min-width: 55px;
	width: auto;
	background-color: #2196f3;
	color: #ffffff;
	padding: 5px;
	text-align: center;
	border-radius: .25rem;
	display: inline-block;
	margin-left: 3px;
	margin-bottom: 3px;
}
.chip-text {
	margin: 0;
	display: inline;
}
.chip-close {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	cursor: pointer;
	margin: 0 4px;
	height: 0;
}
